.video-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  min-height: 360px;
}

.video-window {
  width: 100%;
  height: 360px;
  background-color: #081e3f;
  border-radius: 8px;
  z-index: 6;
  filter: drop-shadow(0px 0px 2px #ffffff);
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.video-title {
  width: 100%;
  background-color: #081e3f;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  font-size: 20px;
  z-index: 6;
  top: 0;
  display: flex;
}

.video-player-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.video-video {
  width: 100%;
  height: 100%;
  background-color: #081e3f;
  justify-content: center;
  align-items: center;
  z-index: 6;
  display: flex;
}
.video-closing-icon {
  filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(24deg)
    brightness(115%) contrast(107%);
  width: 35px;
  height: 35px;
  margin-right: 5px;
  cursor: pointer;
}

.video-closing-icon-wrapper {
  width: 15%;
  display: flex;
  justify-content: flex-end;
}
.popup-invisible-title-text {
  display: none;
  flex-grow: 0;
}

.popup-title-video-title {
  display: flex;
  text-align: center;
  width: 100%;
  justify-content: flex-start;
}
@media screen and (min-width: 770px) {
  .popup-invisible-title-text {
    display: block;
    width: 15%;
  }
  .video-window {
    width: 650px;
    height: 430px;
  }
}

@media screen and (min-width: 850px) {
  .video-window {
    width: 800px;
    height: 500px;
  }
  .popup-invisible-title-text,
  .video-closing-icon-wrapper {
    width: 5%;
  }
}
