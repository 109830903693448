.landing-page-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  white-space: normal;
}

.lp-title {
  width: 95%;
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 20px;
  font-family: "Lato", sans-serif;
  font-size: 18px;
  font-weight: 500;
}

.lp-title-bold-text {
  font-family: "Lato", sans-serif;
  font-size: 18px;
  font-weight: 1000;
}

.lp-middle-section {
  width: 100%;
}

.lp-middle-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.lp-middle-title {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  margin-top: 12px;
}

.lp-middle-text-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
}

.lp-middle-text {
  padding: 10px;
  text-align: center;
  width: 90%;
  background-color: #dce7ef;
  margin-top: 10px;
  border-radius: 8px;
  font-family: Mulish;
  font-size: 14px;
}

.lp-title-icon {
  width: 20px;
  height: 20px;
}

.lp-end-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin-top: 30px;
}

.lp-end-button {
  margin-bottom: 30px;
  width: 90%;
  border: none;
  color: white;
  background-color: #081e3f;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  height: 50px;
}

.lp-end-button-text {
  flex-grow: 1;
  text-align: center;
  margin-left: 40px;
  font-size: 16px;
  font-family: Mulish;
}

.lp-button-icon {
  width: 40px;
  height: 40px;
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%)
    contrast(103%);
}

.landing-page-gov-logo-wrapper {
  margin-top: 2px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.landing-page-gov-logo {
  width: 300px;
  max-width: 95%;
}

.lp-legal {
  width: 100%;
  max-width: 680px;

  padding: 10px;
  font-size: 14px;
}

.legal {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}
.legal blockquote {
  padding-left: 30px;
}

@media screen and (min-width: 768px) {
  .lp-middle-section {
    display: flex;
    height: 100%;
  }
  .lp-middle-text-wrapper {
    justify-content: space-between;
    height: 100%;
  }

  .lp-middle-text {
    height: 130px;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .lp-end-button {
    width: 250px;
  }

  .lp-title-icon {
    width: 40px;
    height: 40px;
  }

  .lp-title,
  .lp-title-bold-text {
    font-size: 30px;
  }

  .lp-end-button {
    margin-bottom: 50px;
  }
}
