.App {
  height: 100%;
  width: 100vw;
}

.special-blue {
  background-color: #a4e1d8;
}

.text-justify {
  text-align: justify;
  text-align-last: center;
}
