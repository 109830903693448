.map-section {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 770px) {
  /* I have found this classname by inspecting the element with google dev tools */
  .mapboxgl-ctrl-top-right {
    display: none;
  }
}

.mapboxgl-popup-content {
  font: 400 15px/22px "Source Sans Pro", "Helvetica Neue", Sans-serif;
  height: auto;
  width: 350px;
}

.video-button {
  font-family: Mulish;
  width: 154px;
  height: 35px;
  border-radius: 8px;
  font-weight: 700;
  font-size: 14px;
  margin-top: 5px;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.minimiser-icon-wrapper {
  height: 1px;
  width: 1px;
  position: absolute;
  right: 35px;
  top: -2px;
  cursor: pointer;
}
.map-video-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

/* FlightSearch component */
.pop-up-flight-icon {
  width: 40px;
  height: 40px;
  margin-right: 5px;
}

.popup-flight-icon-selected {
  width: 40px;
  height: 40px;
  margin-right: 5px;
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(28deg) brightness(103%)
    contrast(104%);
}

.popup-flight-btn-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.popup-flight-btn {
  width: 95%;
  min-height: 40px;
  font-family: Mulish;
  border-radius: 8px;
  font-weight: 700;
  font-size: 14px;
  border: none;
  margin-bottom: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #081e3f;
  background-color: #fff;
  border: 1px solid #081e3f;
}
.popup-flight-btn:disabled {
  background-color: rgb(228, 225, 225);
}

.popup-flight-btn:hover {
  background-color: rgb(228, 225, 225);
}

.popup-flight-btn-selected,
.popup-flight-btn-selected:hover {
  background: #081e3f;
  color: white;
}

.map-location-icon {
  width: 40px;
  height: 40px;
}

.popup-icon-text-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: rgb(166, 206, 227);
  border-radius: 3px;
  padding: 6px;
  margin-bottom: 6px;
}

.popup-paragraph {
  padding: 6px;
  margin-bottom: 6px;
}

/* for the altitude and usage text background */
.popup-icon-summary-text-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 3px;
  padding: 6px;
  margin-bottom: 6px;
}

.pdf-report-button {
  width: 100%;
  min-height: 40px;
  font-family: Mulish;
  border-radius: 8px;
  font-weight: 700;
  font-size: 14px;
  color: #081e3f;
  background-color: transparent;
  border: 1px solid #99a3af;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
