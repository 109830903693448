@media screen and (min-width: 771px) {
  .show-mobile-filters-button {
    display: none;
  }
}

.show-mobile-filters-button-container {
  position: absolute;
  bottom: 15px;
  width: 100%;
  height: 30px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 995;
}

.show-mobile-filters-container {
  display: flex;
  width: 100%;
  height: 100;
}

.show-mobile-filters-button {
  width: 100px;
  height: 36px;
  color: white;
  background-color: #4fb2b4;
  border-radius: 6px;
  border: none;
  font-weight: 500;
  font-size: 14px;
}

.light-mode .show-mobile-filters-button {
  background-color: #081e3f;
  color: white;
}
