.helpbox-modal-wrapper {
  background-color: white;
  width: 650px;
  height: 530px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
}

.helpbox-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-height: 500px;
  overflow-y: scroll;
  border-radius: 8px;
  gap: 20px 10px;
}

.helpbox-left {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 20px;
  padding: 0 10px;
}

.helpbox-right {
  width: 100%;
}

.helpbox-left-text-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.helpbox-closing-icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.helpbox-closing-icon {
  width: 30px;
  height: 30px;
  margin-right: 5px;
}

.helpbox-icons {
  width: 30px;
  height: 30px;
}

.helpbox-text {
  width: 90%;
  text-align: left;
  font-family: "Lato", sans-serif;
}

.helpbox-text u {
  cursor: pointer;
}

.helpbox-noise-diagram-vertical,
.helpbox-noise-diagram-horizontal {
  width: 100%;
}
.helpbox-noise-diagram-horizontal {
  display: none;
}

.helpbox-slide-closing-icon-wrapper {
  display: flex;
  height: 100%;
}


@media screen and (min-width: 780px) {
  .helpbox-noise-diagram-vertical {
    display: none;
  }
  .helpbox-noise-diagram-horizontal {
    display: block;
  }
}
@media screen and (min-width: 1200px) {
  .helpbox-modal-wrapper {
    width: 850px;
    height: 530px;
  }

  .helpbox-left {
    padding: 0 30px;
  }

  .helpbox-icons {
    width: 40px;
    height: 40px;
  }
}

@media screen and (min-width: 1800px) {
  .helpbox-modal-wrapper {
    width: 1000px;
    height: 555px;
  }

  .helpbox-left-text-wrapper {
    margin-left: 10px;
  }

  .helpbox-icons {
    width: 50px;
    height: 50px;
  }
}
