.modal-background {
  width: 100%;
  height: 100%;
  z-index: 999;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(36, 34, 33, 0.7);
}
