.popup-wrapper {
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 360px;
  max-height: 630px;
  overflow: scroll;
  height: auto;
  color: black;
  border-radius: 5px;
  border: 2px solid rgba(8, 30, 63, 0.1);
  padding: 12px;
  /* not showing the scrollbars */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.popup-wrapper::-webkit-scrollbar {
  display: none;
}

.track-popup-btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-grow: 2;
  margin-left: 10px;
}

.popup-play-icon {
  width: 22px;
  height: 22px;
  transform: rotate(90deg);
}

.popup-title {
  height: 30px;
  display: flex;
  align-items: center;
  padding-left: 4px;
}

.popup-noise-metric {
  display: flex;
  padding: 6px;
  margin-bottom: 6px;
  text-align: center;
}
.popup-noise-metric button,
.popup-noise-metric button:disabled:hover {
  width: 100%;
  min-height: 40px;
  font-family: Mulish;
  border-radius: 0;
  font-weight: 700;
  font-size: 14px;
  border: none;
  display: flex;
  align-items: center;
  color: #081e3f;
  background-color: #fff;
  border: 1px solid #081e3f;
  text-align: center;
}

.popup-noise-metric button:first-child {
  border-radius: 8px 0 0 8px;
}
.popup-noise-metric button:last-child {
  border-radius: 0 8px 8px 0;
}
.popup-noise-metric button:hover {
  background-color: rgb(228, 225, 225);
}

.popup-noise-metric button:disabled {
  opacity: 0.5;
}

.popup-noise-metric button.metric-selected,
.popup-noise-metric button.metric-selected:hover {
  background: #081e3f;
  color: white;
}

@media screen and (max-width: 771px) {
  .popup-wrapper {
    top: auto;
    left: 5px;
    right: 5px;
    width: auto;
    z-index: 990;
    padding-bottom: 51px;
    bottom: 0;
    height: auto;
    max-height: 45%;
    border-radius: 5px 5px 0px 0px;
  }
}
