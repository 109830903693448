.pdf-modal-wrapper {
  background-color: white;
  width: 700px;
  height: fit-content;
  height: 80%;
  overflow-y: initial;
}

.pdf-title-section {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  gap: 10px;
  border-bottom: 1px solid #f5f5f5;
}

.pdf-closing-icon {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.pdf-section {
  width: 100%;
  height: 100%;
}

iframe {
  width: 100%;
  height: 100%;
}
