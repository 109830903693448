.address-title {
  display: flex;
  align-items: center;
  height: 40px;
  justify-content: space-between;
}

.adress {
  font-family: "Calibri", sans-serif;
  font-size: 14px;
  margin-left: 10px;
}

.address-loc-marker {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.copy-emphasize {
  background: rgb(166, 206, 227);
  border-radius: 3px;
  padding: 6px;
  margin-bottom: 6px;
  text-align: center;
}

.popup-text {
  padding: 6px;
  margin-bottom: 6px;
  text-align: center;
}

@media screen and (max-width: 771px) {
  .popup-wrapper {
    bottom: 0;
    top: auto;
    left: auto;
    right: auto;
    width: 100%;
    z-index: 990;
    padding-bottom: 51px;
    height: auto;
    max-height: 55%;
  }
}
