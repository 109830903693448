.modal-wrapper {
  background-color: white;
  width: 100%;
  max-height: 90%;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.slide-modal {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
}

.modal-content {
  width: 100%;
  border: none;
  height: 100%;
}

.modal-closing-icon {
  width: 30px;
  height: 30px;
}

.modal-arrow-wrapper {
  min-width: 48px;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.modal-arrow-active {
  cursor: pointer;
}

.modal-arrow-wrapper img {
  width: 48px;
  height: 48px;
}

.modal-left-arrow {
  transform: rotate(180deg);
}
.modal-intro-screenshot-small-screen img,
.modal-intro-screenshot-large-screen img {
  max-height: 100%;
  max-width: 100%;
}
.modal-intro-screenshot-large-screen {
  flex: 1 1 auto;
  width: 100%;
}

.modal-intro-footer a {
  color: #081e3f;
}

.modal-end-button {
  margin-bottom: 10px;
  border: none;
  color: white;
  background-color: #081e3f;
  border-radius: 8px;
  padding: 10px 20px;
  height: 50px;
}

.modal-wrapper {
  max-width: 900px;
  max-height: min(730px, 95%);
  margin: 20px;
}

.modal-bottom {
  padding: 10px;
}
