#filter-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #081e3f;
  color: #fff;
  width: 390px;
  height: 100%;
  flex: 0 0 200px;
}

#filter-wrapper::-webkit-scrollbar {
  display: none;
}

#filter-wrapper {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.checkbox-label {
  font-family: Mulish;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  width: 265px;
  display: flex;
  align-items: center;
}

.filter-section {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.filter-checkboxes {
  margin-right: 10px;
  accent-color: #fff;
  appearance: none;
  -webkit-appearance: none;
  border: 1px solid #fff;
  height: 20px;
  width: 20px;
  border-radius: 4px;
}

.filter-checkboxes:checked {
  background: url(../images/Legend/white-box.png);
  background-repeat: no-repeat;
  background-size: 12px 12px;
  background-position: center;
}

.light-mode .filter-checkboxes {
  border: 1px solid #081e3f;
}

.light-mode .filter-checkboxes:checked {
  background: url(../images/Legend/navy-blue-box.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 12px 12px;
}

.checkbox-container {
  display: flex;
  align-items: center;
  padding-left: 24px;
  margin-top: 16px;
  width: 100%;
}

.operating-mode-buttons {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
  margin-left: 5px;
}

#application-title {
  width: 100%;
  margin: 0;
  padding: 6px 15px;
  margin-block-start: 0px;
  margin-block-end: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: Mulish;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  background: #081e3f;
  color: white;
}

.filters-1st-hr {
  margin-top: 0;
  width: 100%;
  opacity: 0.5;
  margin-bottom: 0px;
}

.left-side-hr-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}

.filter-hr {
  width: 95%;
  opacity: 0.5;
  height: 1px;
  border: 0.5px solid rgba(255, 255, 255, 0.7);
  margin: 0px;
  margin-top: 16px;
  margin-bottom: 0px;
}

.light-mode .filter-hr {
  border: 0.5px solid #081e3f1a;
  opacity: 0.5;
}

.filter-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
}

.info-icon {
  font-size: 20px;
  margin-right: 12px;
  cursor: pointer;
}

.info-icon:focus:not(:focus-visible) {
  outline: none;
}

.filter-info,
.filter-info-persistent,
.info-persistent {
  font-family: Mulish;
  font-weight: 400;
  font-size: 14px;
  padding: 0 12px;
}

.info-persistent {
  padding: 12px;
  margin: 0;
}

.filter-info-persistent {
  padding: 0 12px;
  margin: 0;
}

.light-mode .filter-info {
  color: #337b7e;
}

.filter-title {
  margin-left: 12px;
  font-family: Mulish;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
}

.filter-title::before {
  content: "◆";
  margin-right: 5px;
  color: white;
}

.light-mode .filter-title::before {
  color: #081e3f;
  opacity: 0.2;
}

.filter-button-m,
.filter-button-xl {
  width: 100%;
  min-height: 40px;
  font-family: Mulish;
  border-radius: 8px;
  font-weight: 700;
  font-size: 14px;
  color: #fff;
  background-color: transparent;
  border: 1px solid #fff;
  padding-left: 0;
  padding-right: 0;
}

.filter-button-xl {
  min-height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.filter-button-m:hover,
.filter-button-xl:hover {
  background-color: #fff;
  color: #081e3f;
}

.filter-button-m:disabled:hover,
.filter-button-xl:disabled:hover {
  background-color: transparent;
  color: #fff;
}

.light-mode .filter-button-m,
.light-mode .filter-button-xl {
  color: #081e3f;
  background-color: transparent;
  border: 1px solid #99a3af;
}

.light-mode .filter-button-m:hover,
.light-mode .filter-button-xl:hover {
  background-color: rgb(228, 225, 225);
}

.light-mode .filter-button-m:disabled:hover,
.light-mode .filter-button-xl:disabled:hover {
  background-color: transparent;
  color: #081e3f;
}

.filter-button-m.button-selected,
.filter-button-xl.button-selected {
  color: #000;
  background-color: #fff;
  border-color: #081e3f;
}

.filter-button-group {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 12px;
  width: 100%;
  padding: 6px 12px;
}

.buttons-holder {
  display: flex;
  flex-direction: column;
  gap: 0;
}

.filter-button-group.procedure-group,
.filter-button-group.event-noise-aircraft {
  flex-wrap: wrap;
}

.filter-button-group.procedure-group .filter-button-m {
  flex-basis: 40%;
  flex-grow: 1;
}

.filter-button-group.event-noise-aircraft .filter-button-xl {
  flex-basis: 30%;
  flex-grow: 1;
}

.filter-button-m-subtitle {
  font-family: Mulish;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  text-align: center;
}

#left-sidebar-last-hr {
  width: 95%;
  opacity: 0.3;
}

.radio-button-test {
  appearance: none;
  width: 100px;
  height: 40px;
  color: #fff;
  background-color: transparent;
  border-radius: 8px;
  border: 1px solid #fff;
  font-weight: 700;
  font-size: 14px;
  margin-top: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.radio-button-test:checked {
  background-color: #fff;
  color: #000;
}

.checkboxes {
  margin-top: 8px;
}

#filter-close-logo {
  display: none;
}

.filtering-title-wrapper {
  width: 320px;
  min-width: 100%;
}

.filter-closing-btn {
  display: none;
  position: fixed;
  font-size: 14px;
  color: #fff;
  background: #4eb2b4;
  border-radius: 8px;
  border: none;
  width: 100px;
  height: 36px;
  font-weight: 500;
  bottom: 10px;
}

.light-mode .filter-closing-btn {
  color: #fff;
  background-color: #071e3e;
}

.filter-footer-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  flex-grow: 1;
}

.filter-footer {
  margin: 12px 0;
  text-align: center;
}

#closing-button-wrapper {
  width: 100%;
  height: 30px;
  position: absolute;
  bottom: 5px;
  display: flex;
  justify-content: center;
}

.light-mode #filter-wrapper,
.filter-button-m:focus,
.filter-button-m:active,
.filter-button-xl:focus,
.filter-button-xl:active {
  background-color: #fff;
  color: #081e3f;
}

.checkbox-container label,
.checkbox-container input[type="checkbox"] {
  cursor: pointer;
}

.light-mode #left-sidebar-last-hr {
  opacity: 0.1;
}

.filter-button-m:disabled,
.filter-button-xl:disabled {
  opacity: 0.5;
}

.light-mode .filter-button-m.button-selected,
.light-mode .filter-button-xl.button-selected,
.light-mode .filter-button-xl:focus {
  background: #081e3f;
  color: white;
}

.noise-btn-wrapper {
  width: 100%;
  display: flex;
  justify-content: left;
  padding: 12px;
}
.noise-choice-btn {
  margin: 0;
  width: 100%;
}
.noise-choice-btn:first-of-type {
  border-radius: 6px 0px 0px 6px;
}
.noise-choice-btn:last-of-type {
  border-radius: 0px 6px 6px 0px;
}

.filter-group-wrapper {
  width: 100%;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox  */
}

.filter-group-wrapper::-webkit-scrollbar {
  display: none;
}

/* styling of the hover text in the lower screens */
.mobile-hover-text {
  font-family: Mulish;
  font-weight: 400;
  font-size: 14px;
  padding: 0 12px;
  margin-top: 10px;
}

.filter-footer-help-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filter-footer-help-icon {
  width: 30px;
  height: 30px;
  filter: invert(93%) sepia(97%) saturate(23%) hue-rotate(128deg)
    brightness(108%) contrast(100%);
  margin: 10px;
}

.light-mode .filter-footer-help-icon {
  filter: none;
}

.filter-footer-help-text {
  cursor: pointer;
  text-align: left;
  margin: 10px;
}

@media screen and (max-width: 770px) {
  #filter-wrapper {
    position: absolute;
    bottom: 0;
    z-index: 999;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    justify-content: space-between;
  }

  .filter-group-wrapper {
    overflow-y: initial;
  }

  .filter-button-group {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    padding: 0px 12px;
  }

  .buttons-holder {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  #application-title {
    font-size: 18px;
    display: flex;
    justify-content: center;
  }

  #filter-close-logo {
    display: flex;
    margin-right: 5px;
  }

  .filtering-title-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .filter-closing-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background: #4eb2b4;
    border-radius: 8px;
    border: none;
    padding: 5px 10px;
    font-weight: 600;
  }

  .light-mode .filter-closing-btn {
    color: #fff;
    background-color: #071e3e;
  }

  #filter-wrapper[mobileFiltering="1"] {
    display: none;
  }

  .filter-footer-help-wrapper {
    width: 100%;
    margin-bottom: 40px;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-height: 723px) {
  .filter-hr {
    margin-bottom: 5px;
    margin-top: 10px;
  }

  #map-left-side-bar {
    overflow-y: scroll;
  }

  #application-title {
    margin-bottom: 5px;
  }

  .filter-button-m {
    min-height: 35px;
  }

  .filter-button-xl {
    min-height: 50px;
  }
}

@media screen and (min-height: 800px) and (max-height: 1020px) and (min-width: 770px) {
  .checkbox-container {
    margin-top: 12px;
  }

  .filter-header {
    margin-top: 15px;
  }

  .checkbox-container {
    margin-top: 18px;
    margin-bottom: 6px;
  }
}

@media screen and (min-width: 770px) {
  .mobile-hover-text {
    display: none;
  }
}

@media screen and (min-width: 320px) and (max-width: 375px) {
  .filter-button-xl,
  .filter-button-m,
  .noise-choice-btn {
    font-size: 13px;
  }
}

@media screen and (min-width: 280px) and (max-width: 320px) {
  .filter-button-xl,
  .filter-button-m,
  .noise-choice-btn {
    font-size: 12px;
  }

  .filter-hr {
    margin-top: 23px;
  }

  .filtering-title-wrapper {
    width: 100%;
  }
}
