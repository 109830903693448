.legend-container {
  display: flex;
  color: white;
}

.legend {
  position: absolute;
  left: 10px;
  top: 72px;
  background-color: #337b7e;
  width: 150px;
  height: auto;
  border-radius: 5px;
  padding: 10px;
  max-width: 300px;
  max-height: 400px;
}

#legend-title {
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
}

.colour-boxes {
  width: 10px;
  height: 9px;
  margin-right: 5px;
  border-radius: 2px;
}

.colour-identifiers {
  font-weight: 400;
  font-size: 14px;
  color: white;
  display: flex;
  align-items: center;
}
.map-option-container {
  display: flex;
}

.map-option-wrapper {
  position: absolute;
  right: 10px;
  bottom: 35px;
  background-color: white;
  border-radius: 5px;
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.map-option-img {
  width: 64px;
  height: 64px;
  border-radius: 5px;
  background-color: linear-gradient(
    180deg,
    rgba(19, 20, 20, 0) 0%,
    #131414 64.06%
  );
  cursor: pointer;
}

#option-overlay-text {
  position: absolute;
  z-index: 1;
  width: 64px;
  height: 15px;
  right: 13px;
  bottom: 40px;
  color: white;
  background: linear-gradient(180deg, rgba(19, 20, 20, 0) 0%, #131414 64.06%);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.legend-info-icon {
  font-size: 20px;
  margin-left: 10px;
  cursor: pointer;
}

.legend-info-icon:focus:not(:focus-visible) {
  outline: none;
}

.legend-info-text {
  font-style: italic;
  opacity: 80%;
}

@media screen and (min-width: 771px) {
  .legend {
    position: absolute;
    left: 10px;
    top: auto;
    bottom: 35px;
    height: auto;
    width: 300px;
  }
}
