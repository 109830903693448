.search-input-wrapper {
  width: 100%;
}

.search-input {
  width: 100%;
}

/* Change (overwrite) some of the original .css classes to adjust the size of the search bar */
.mapboxgl-ctrl-geocoder {
  /* margin-left: 2%; */
  font-size: 21px;
  line-height: 24px;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Helvetica Neue",
    Arial, Helvetica, sans-serif;
  position: relative;
  background-color: #fff;
  /* width: 100%; */
  height: 45px;
  min-width: 100%;
  z-index: 1;
  border-radius: 8px;
  transition: width 0.25s, min-width 0.25s;
}

.mapboxgl-ctrl-geocoder--input {
  font: inherit;
  width: 100%;
  border: 0;
  margin: 0;
  height: 45px;
  color: #404040; /* fallback */
  color: rgba(0, 0, 0, 0.75);
  padding: 6px 45px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.mapboxgl-ctrl-geocoder .suggestions {
  background-color: #fff;
  border-radius: 4px;
  left: 0;
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  width: 100%;
  top: 110%; /* fallback */
  top: calc(100% + 6px);
  z-index: 1000;
  overflow: hidden;
  font-size: 18px;
}

.mapboxgl-ctrl-geocoder--icon-search {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 25px;
  height: 25px;
}

@media screen and (max-width: 770px) {
  .search-input-wrapper {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 80px;
    left: 0px;
    width: 100%;
  }

  .mapboxgl-ctrl-geocoder {
    margin-left: 0px;
  }
}