/* for mobile language settings */
@media screen and (max-width: 768px) {
  .lang-options-container {
    width: 100%;
    height: 100%;
    background-color: rgba(61, 61, 61, 0.4);
    backdrop-filter: blur(2px);
    z-index: 999;
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .lang-options {
    display: flex;
    z-index: 999;
    width: 240px;
    background-color: white;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .lang-option-title {
    width: 240px;
    height: 45px;
    display: flex;
    align-items: center;
    margin-left: 10px;
  }

  .lang-option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0.5px solid rgb(212, 203, 203);
    width: 100%;
    height: 35px;
  }

  .lang-opt-hr {
    margin: 0;
  }

  .language-section {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .lang-option-checkbox {
    margin-right: 10px;
  }
  .lang-option-label {
    margin-left: 10px;
  }

  .lang-button-section {
    height: 45px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #lang-button {
    background-color: #091e3f;
    color: white;
    border-radius: 4px;
    border: none;
    width: 120px;
    height: 30px;
  }

  .desktop-hr-tag {
    display: none;
  }
}
/* For desktop display */
@media screen and (min-width: 768px) {
  .lang-options-container {
    background-color: white;
    width: 200px;
    z-index: 999;
    position: absolute;
    right: 10px;
    border-radius: 5px;
    top: 65px;
  }

  .lang-option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 35px;
    cursor: pointer;
  }

  .lang-option-checkbox {
    margin-right: 10px;
    cursor: inherit;
  }
  .lang-option-label {
    margin-left: 10px;
    cursor: inherit;
  }

  .desktop-hr-tag {
    margin: 0;
  }
  .lang-button-section {
    display: none;
  }

  .lang-option-title {
    display: none;
  }

  .lang-opt-hr {
    display: none;
  }
}
