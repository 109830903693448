@media screen and (min-width: 900px) {
  #top-search-bar {
    display: none;
  }
}

#top-bar {
  background: #081e3f;
  height: 70px;
  width: 100%;
}

#top-bar-government-logo {
  margin-left: 10px;
  width: 300px;
}

.top-bar-link {
  display: flex;
  width: 320px;
}

.top-navbar {
  height: 72px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#navbar-logo {
  margin-right: 10px;
  margin-left: 5px;
}

#top-bar-menu {
  color: white;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 10px;
  margin-left: 10px;
  width: 200px;
}

#top-bar-language-option {
  width: 30px;
  height: 20px;
  border-radius: 3px;
  margin-bottom: 5px;
}

#topbar-desktop-lang-opt {
  font-weight: 400;
  font-size: 16px;
  width: 100%;
}

.top-bar-button-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 15px;
  margin-right: 5px;
}

.top-bar-button-wrapper button {
  min-height: 45px;
  border-radius: 8px;
  font-family: Mulish;
  font-weight: 700;
  font-size: 14px;
  color: #fff;
  background-color: transparent;
  border: 1px solid #fff;
  padding: 0 8px;
  white-space: nowrap;
}

.top-bar-button-wrapper button:hover {
  background-color: #fff;
  color: #081e3f;
}

@media screen and (max-width: 770px) {
  #topbar-desktop-lang-opt {
    display: none;
  }

  .top-bar-button-wrapper {
    display: none;
  }
}

@media screen and (min-width: 771px) {
  #top-bar-language-option {
    display: none;
  }
}

.top-logo-wrapper {
  width: 325px;
}

.search-bar-wrapper {
  width: 100%;
}

.mobile-dropdown-logo {
  display: none;
}

.stacked-logo {
  display: none;
}

@media screen and (max-width: 770px) {
  .search-bar-wrapper {
    width: 366px;
  }
}

@media screen and (max-width: 395px) {
  #top-bar-menu {
    width: 30px;
  }

  .desktop-dropdown-logo {
    display: none;
  }

  .mobile-dropdown-logo {
    display: flex;
    filter: invert(100%) sepia(0%) saturate(4007%) hue-rotate(162deg)
      brightness(128%) contrast(112%);
    width: 20px;
    height: 20px;
    margin-right: 5px;
    margin-top: 3px;
  }
}

@media screen and (max-width: 365px) {
  #top-bar-menu {
    width: 20px;
  }

  #top-bar-government-logo {
    display: none;
  }

  .stacked-logo {
    display: flex;
    width: 80px;
    height: 50px;
    margin-left: 12px;
  }

  .mobile-dropdown-logo {
    display: flex;
    filter: invert(100%) sepia(0%) saturate(4007%) hue-rotate(162deg)
      brightness(128%) contrast(112%);
    width: 20px;
    height: 20px;
    margin-right: 5px;
    margin-top: 3px;
  }

  .top-bar-link {
    width: 200px;
  }
}

@media screen and (min-width: 1825px) and (max-width: 2000px) {
  .top-bar-link {
    width: 323.5px;
  }
}

@media screen and (min-width: 2000px) and (max-width: 2200px) {
  .top-bar-link {
    width: 326.5px;
  }
}
